.news_items {
  list-style-type: none;
  padding: 0;

  .panel {
    cursor: move;

    img {
      float: left;
      max-width: 100px;
      max-height: 100px;
      margin: 0 10px 10px 0;
    }

  }

  li {
    display: block;
    padding: 0;
  }
}
