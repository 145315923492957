.list_symbols span {
  padding: $padding-small-vertical $padding-small-horizontal;
  font-size: $font-size-small;
  line-height: $line-height-small;
}

.dashboard_menu_tree {
  div.inner:hover {
    background: #e6e6e6;
  }
}
