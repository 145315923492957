/* nuCMS Administration Panel Layout */

/* Left section ------------------------------------------------------------ */

$left_bg : #386383;
$left_fg : #fff;

//$left_bg: darken($nucolor, 20);
//$left_fg: #fff;

div#left {
  width: 260px;
  float: left;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px darken($gray-lighter, 10%) solid;
  background: $gray-lighter;
  height: 100%;
  background: $left_bg;
  color: $left_fg;

  #nucms_logo {
    display: block;
    width: 100%;
    padding: 14px 0;
    background: darken($left_bg, 10);
    img {
      display: block;
      margin: 0 auto;
    }
  }

  #user_actions {
    .username {
      margin: 5px;
      line-height: 36px;
    }
    .logout {
      float: right;
      margin: 5px;
      border-radius: $btn-border-radius-base;
      border: 1px $gray-lighter solid;
    }
  }
  // admin panel menu sections

  .heading {
    padding: 4px 5px;
    background: darken($left_bg, 10);
    .glyphicon {
      margin-right: 5px;
    }
  }

  a {
    display: block;
    color: $left_fg;
    &:hover {
      text-decoration: none;
    }

  }

  ul {
    list-style-type: none;
    padding: 0;

    span.glyphicon {
      margin-right: $padding-base-horizontal;
    }

    &.select_lang {
      img {
        vertical-align: top;
        margin: 2px 2px 0 0;
      }
      a {
        padding: 2px 0 2px 3px;
      }
    }

    li:hover {
      background-color: lighten($left_bg, 10);
    }

  }

  ul.list {
    li {
      background: url("../img/node.png") 9px -5px no-repeat;
    }

    li:last-child {
      background: url("../img/lastnode.png") 9px -5px no-repeat;
    }

    li:hover {
      background-image: url('../img/node_current.png');
    }

    li:last-child:hover {
      background-image: url('../img/lastnode_current.png');
    }

    li:hover {
      background-color: lighten($left_bg, 10);
    }

    a {
      padding: 2px 0 2px 25px;
    }

  }
}

/* Right section ------------------------------------------------------------ */

div#right {
  height: 100%;
  width: auto;
  overflow: hidden;

  #sticky {
    position: relative;
    overflow: hidden;
    border-bottom: 1px #ccc solid;
    background: $panel-default-heading-bg;
    //    z-index: 15;
    padding: $line-height-computed / 2;

    h2 {
      display: block;
      font-family: signikaregular, Arial, serif;
      color: $nucolor;
      padding: 0 0 $line-height-computed / 2 0;
      margin: 2px 0 $line-height-computed / 2 0;
      border-bottom: 1px darken($gray-lighter, 5%) solid;

      span {
        color: darken($nucolor, 20%);
      }
    }

    .pagination {
      margin: 0;
      float: right;
    }

  }

  #content {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    z-index: 10;
    width: auto;
    position: relative;
    padding: 10px; // pixele, nie paddingi bootstrapowe, tak ma być (js)
    background: #e9e9e9;
  }

}

#overlay {
  background: rgba(0, 0, 0, 0.2) url('../img/overlay.gif') 50% 49%  no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 998;

  span {
    display: block;
    text-align: center;
    width:300px;
    height:20px;
    position:absolute;
    left:50%;
    top:45%;
    margin:-20px 0 0 -150px;
    font-size: 20px;
    font-weight: bold;
    text-shadow: 0 0 3px rgba(255, 255, 255, 1);
    color: #000;
    z-index: 999;
  }
}
