.glyphicon-none:before {
  content: "\2122";
  color: transparent !important;
}

.btn {
  font-family: arial, Helvetica, sans-serif;
}

.btn .glyphicon {
  margin-right: 8px;
}

.btn.btn-icon .glyphicon {
  margin-right: 0;
}

.btn .glyphicon-nomargin, .glyphicon-nomargin {
  margin-right: 0;
}

.space-right {
  margin-right: $padding-base-vertical;
}

.space-left {
  margin-left: $padding-base-vertical;
}

.space-top {
  margin-top: $line-height-computed;
}

.space-bottom {
  margin-bottom: $line-height-computed;
}

.table-condensed form {
  display: inline;
}

.table-hover {
  > tbody > tr:hover .btn {
    opacity: 1;
  }
}

.table-hover .btn {
  opacity: 0.6;
}

.table {
  background: #fff;
  border: 1px $panel-default-border solid;

  &.table-condensed {
    td, th {
      padding: 2px 5px;
      vertical-align: middle;
    }
  }

}


.gray {
  color: $gray-light;
}

hr.small_hr {
  margin: 0.3em 0;
  border-color: #ccc;
}


ul.zero-margin {
  margin-left: 0;
  padding-left: 15px;
}

table .btn-xs {
  padding: 4px 6px;
}

.no-gutter {
  padding-left: 0;
  padding-right: 0;

}

.popover-content {
  color: #000 !important;
}

@media('min-width: 1024px') {
  .modal-dialog {
    width: 800px;
  }
}

.panel {
  .panel {
    margin-bottom: 10px;
  }
}
