

#rwd_actions {
  margin-bottom: 1em;
}

.rwd_view {
  display: block;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  background: #ccc;

  * {
    box-sizing: border-box;
  }

  .rwd_row {
    overflow: hidden;
    min-height: 50px;
    clear: both;
  }

  .col {
    float: left;
    //min-height: 50px;
    padding: 2px;
    height: 100%;

    .user_area {
      background-color: $alert-success-bg;
      border-color: $alert-success-border;
      min-height: 50px;
    }

    .common_element {
      background: $alert-info-bg;
      border-color: $alert-info-border;

      .title {
        display: block;
        text-align: center;
      }

      .btn {
        margin-top: 1em;
      }

    }

    .empty_cell {
      background: #fff;
    }

    .common_layout {
      background-color: $alert-warning-bg;
      border-color: $alert-warning-border;
    }

  }

  .layout {
    overflow: hidden;
    clear: both;

    .empty_cell {
      text-align: center;
      margin: 1em auto;
    }
  }

  .layout_row {
    overflow: hidden;
    //margin-bottom: 5px;
  }

  .layout_col {
    background: #fafafa;
    border: 1px #e6e6e6 solid;
    padding: 5px;
    overflow: hidden;
    float: left;
    position: relative;
    text-align: center;
    cursor: move;

    &.new {
      animation: tr_highlight 2s;
    }

    .ui-resizable-e {
      background: #fff;
      right: -4px;
      border-left: 1px #ddd solid;
    }

    hr {
      margin: 5px 0;
    }

  }

  .layout_cell_add_column {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
}

.rwd12 {

  @for $col_w from 1 through 12 {
    .col-#{$col_w} {
      width: 100% * $col_w / 12;
    }
  }

}

.rwd1 {
  .col {
    width: 100%;
  }
}

// themes

.rwd_view.rwd_theme_edit {
  padding-top: 7px;

  .rwd_row {
    padding: 0 3px 7px 3px;
    .ui-resizable-s {
      height: 7px;
      background: #e6e6e6;
      bottom: 0;
    }
  }

  .col {
    .inner {
      border: 1px #ccc solid;
      background: #fff;
      padding: 10px;
      height: 100%;
    }
    .ui-resizable-e {
      right: -4px;
    }

    hr {
      margin: 5px 0;
    }

    .label {
      margin-bottom: 5px;
    }
  }
}

// used in dashboard /pawe

.rwd_view.dashboard_theme_preview {
  padding: 2px 0;
  background: #eee;

  .rwd_row {
    padding: 0 2px;
  }

  .col {
    min-height: 0;
    margin-bottom: 0;
    padding: 0;

    .inner {
      min-height: 60px;
      text-align: center;
      font-size: 0.9em;
      padding: 2px;
      vertical-align: middle;
      display: table;
      width: 100%;
    }

    .empty_cell, .user_area, .common_element, .common_layout {
      display: table-cell;
      vertical-align: middle;
      border: 1px #ddd solid;
      padding: 5px 0;
    }
  }

  .common_layout {
    padding: 3px;
  }

  .layout_col {
    padding: 5px 0;
  }

}

// common_layouts, pages_layouts

.rwd_view.common_layout_preview, .rwd_view.rwd_view_layout {
  background: #fff;
  padding: 2px 1px;
  border: 1px $panel-default-border solid;
  .inner {
    //display: table;
    width: 100%;
    height: 100%;
    border: 1px #ddd solid;
    .empty_cell, .user_area, .common_element, .common_layout {
      padding: 5px 0;
      text-align: center;
      height: 100%;
      //display: table-cell;
      //vertical-align: middle;
    }
    .common_layout.no_padding, .user_area.no_padding {
      padding-top: 0;
      padding-bottom: 0;
    }

    .title {
      display: block;
    }
  }

  .layout {
    padding: 5px;
  }

  .cell_actions {
    margin-top: 5px;
  }

  .add_cell_below {
    clear: both;
    margin: 0 0 5px 5px;
  }

  .layout_col {
    padding: 10px 0;
    .title {
      margin-bottom: 10px;
    }
  }

}

.rwd_view.rwd_select_layout {
  padding: 2px 0;
  background: #eee;

  .col {
    padding: 0;

    .inner {
      min-height: 60px;
      text-align: center;
      font-size: 0.9em;
      padding: 2px 2px;
      vertical-align: middle;
      display: table;
      width: 100%;

      & > div {
        display: table-cell;
        vertical-align: middle;
        border: 1px #ddd solid;
      }
    }
  }

  .layout_col {
    display: table;
    text-align: center;
    min-height: 60px;
    height: 100%;
    background-color: $alert-success-bg;
    border-color: $alert-success-border;

    .cell_content {
      display: table-cell;
      vertical-align: middle;
      height: 100%;
      text-align: center;
    }
  }
}

.rwd_preview_btn {
  display: block;
  white-space: normal;
  padding: 8px 5px;
  margin-bottom: 1em;

  > .title {
    display: block;
    font-weight: bold;
    margin-bottom: 1em;
  }

  &:hover {
    background-color: $alert-warning-bg;
  }
  &.current {
    background-color: darken($alert-warning-bg, 10);
  }
}


.rwd_view.rwd_view_layout {

  .btn-default {
    opacity: 0.7;
    transition: opacity 0.3s;
    &:hover {
      opacity: 1.0;
    }
  }

  .common_element .btn {
    opacity: 0.2;
    transition: opacity 0.3s;
  }

  .common_element:hover .btn {
    opacity: 1.0;
  }


}
