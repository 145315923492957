
.mce-fullscreen {
  background: #fff !important;
  position: relative !important;
  width: 100% !important;
}

div.mce-fullscreen {
  position: absolute !important;
}

.mce-i-nu_fullscreen:before {
  content: "\e023" !important;
}

body.mce-fullscreen {
  #content {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    overflow: hidden;
    height: 100%;
  }

  #content::-webkit-scrollbar {
    display: none;  // Safari and Chrome
  }
}