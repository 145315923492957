#images_section .image {
  .thumb img {
    border: 1px $panel-default-border solid;
    max-height: 100px;
    margin-right: 10px;
    float: left;
  }

  .fileupload-progress {
    display: none;

    span {
      float: left;
    }

    .progress {
      margin-left: 10px;
      width: 400px;
      float: left;
    }

    .progress-extended {
      margin-left: 10px;
      float: left;
    }
  }


  .add_files {
    &:hover {
      cursor: pointer;
      button {
        background-color: darken($btn-warning-bg, 10%);
        border-color: darken($btn-warning-border, 12%);
      }
    }
  }
}

#image_edit_modal {

  .modal-dialog {
    width: 900px;
    //    overflow: hidden;
  }

  .preview {
    //    min-height: $line-height-computed * 10 + 3;
    //    overflow: hidden;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
}

#thumb_edit_modal {

  #thumb_preview {
    img {
      outline: 1px #ddd dashed;
      //max-width: 414px;
    }
  }

  .modal-dialog {
    width: 900px;
  }
}

.panel-thumb {
  cursor: move;
}

.jcrop-holder {
  direction: ltr;
  text-align: left;
}

/* Selection Border */
.jcrop-vline, .jcrop-hline {
  background: #ffffff url("../img/jcrop.gif");
  font-size: 0;
  position: absolute;
}

.jcrop-vline {
  height: 100%;
  width: 1px !important;

  &.right {
    right: 0;
  }
}

.jcrop-hline {
  height: 1px !important;
  width: 100%;

  &.bottom {
    bottom: 0;
  }

}

/* Invisible click targets */

.jcrop-tracker {
  height: 100%;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

/* Selection Handles */

.jcrop-handle {
  background-color: #333333;
  border: 1px #eeeeee solid;
  width: 7px;
  height: 7px;
  font-size: 1px;
  &.ord-n {
    left: 50%;
    margin-left: -4px;
    margin-top: -4px;
    top: 0;
  }
  &.ord-s {
    bottom: 0;
    left: 50%;
    margin-bottom: -4px;
    margin-left: -4px;
  }
  &.ord-e {
    margin-right: -4px;
    margin-top: -4px;
    right: 0;
    top: 50%;
  }
  &.ord-w {
    left: 0;
    margin-left: -4px;
    margin-top: -4px;
    top: 50%;
  }
  &.ord-nw {
    left: 0;
    margin-left: -4px;
    margin-top: -4px;
    top: 0;
  }
  &.ord-ne {
    margin-right: -4px;
    margin-top: -4px;
    right: 0;
    top: 0;
  }
  &.ord-se {
    bottom: 0;
    margin-bottom: -4px;
    margin-right: -4px;
    right: 0;
  }
  &.ord-sw {
    bottom: 0;
    left: 0;
    margin-bottom: -4px;
    margin-left: -4px;
  }

}

/* Dragbars */

.jcrop-dragbar {
  &.ord-n, &.ord-s {
    height: 7px;
    width: 100%;
  }
  &.ord-e, &.ord-w {
    height: 100%;
    width: 7px;
  }
  &.ord-n {
    margin-top: -4px;
  }
  &.ord-s {
    bottom: 0;
    margin-bottom: -4px;
  }
  &.ord-e {
    margin-right: -4px;
    right: 0;
  }
  &.ord-w {
    margin-left: -4px;
  }
}

/* The "jcrop-light" class/extension */
.jcrop-light {
  .jcrop-vline, .jcrop-hline {
    background: #ffffff;
    filter: alpha(opacity=70) !important;
    opacity: .70 !important;
  }

  .jcrop-handle {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    background-color: #000000;
    border-color: #ffffff;
    border-radius: 3px;
  }
}

/* The "jcrop-dark" class/extension */

.jcrop-dark {
  .jcrop-vline, .jcrop-hline {
    background: #000000;
    filter: alpha(opacity=70) !important;
    opacity: 0.7 !important;
  }

  .jcrop-handle {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    background-color: #ffffff;
    border-color: #000000;
    border-radius: 3px;
  }
}

/* Simple macro to turn off the antlines */
.solid-line {
  .jcrop-vline, .jcrop-hline {
    background: #ffffff;
  }
}

/* Fix for twitter bootstrap et al. */
.jcrop-holder img, img.jcrop-preview {
  max-width: none;
}


#images_edit_modal {
  .modal-dialog {
    width: 900px;
  }
}

#images_section.in_modal {
  h4 {
    margin: 0;
  }

  hr {
    margin: 10px 0;
    padding: 0;
  }
  
  hr:first-child {
    display: none;
  }

  .fileupload-progress {

    .progress {
      width: 200px;
    }
  }

  .original_image_thumb {
    overflow: hidden;
    margin: 8px 0 5px 0;

    .thumb {
      overflow: hidden;
      img {
        display: block;
        max-width: 180px;
        max-height: 180px;
        padding: 2px;
      }
    }
  }

  .actions {
    clear: both;
  }

  .thumb_section {
    .thumb_preview {
        max-width:100%;
    }
    img {
      //max-width: 100%;
      outline: 1px #ddd dashed;
    }
  }

}

