.layout_elements_sortable {
  ol {
    //width: auto;
    background: $gray_light;
    border: 1px #f00 solid;
    width: 100%;
    padding-left: 0;
    box-sizing: border-box;
    overflow: hidden;
    li {
      width: 40%;
      overflow: hidden;
      border: 1px #F4C20B solid;
      box-sizing: border-box;
      display: inline-block;
    }
    li.placeholder {
      min-height: 50px;
    }
  }
  .ui-sortable-handle {
    box-sizing: border-box;
  }
  .spacer {
    height: 50px;
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
  }
}