.nl_view {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 600px;
  overflow: hidden;
  margin-top: 1em;
  .nl_row {
    width: 600px;
    overflow: hidden;

    &:hover {
      td {
        background: #fff;
      }

    }

    .empty {
      padding: 10px;
    }
  }

  .actions {
    vertical-align: top;
    padding: 25px 10px;
  }

}

.nl_topic {
  width: 600px;
  margin: 0 auto 10px auto;
}

.nl_preview {
  font-family: sans-serif;

  p {
    margin: 0;
  }

}