//
// Breadcrumbs
// --------------------------------------------------

.breadcrumb {
  list-style: none;
  display: block;
  font-family: signikaregular;
  color: $nucolor;
  padding: 0 0 $line-height-computed / 2 0;
  margin: 0 0 $line-height-computed / 2 0;
  border-bottom: 1px darken($gray-lighter, 5%) solid;
  font-size: $font-size-h2;

  > li {
    display: inline-block;

    + li:before {
      content: "#{$breadcrumb-separator} "; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
      color: $nucolor;
    }

    a {
      color: $nucolor;
    }
  }

  > .active {
    color: darken($nucolor, 20%);
  }

}

.breadcrumb.breadcrumb_single {
  margin: 0;
  padding: 0;
  border-bottom: 0;
}