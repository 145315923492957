.add_images {
  position: relative;
  height: 34px;
  width: 120px;
  float: left;
  margin-right: 3px;

  input {
    position: absolute;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 34px;
  }

  button {
    text-align: center;
    position: absolute;
    width: 100%;
    top: 0;
  }

  &:hover {
    cursor: pointer;
    button {
      background-color: darken($btn-primary-bg, 10%);
      border-color: darken($btn-primary-border, 12%);
    }
  }
}

.gallery_image {

  .thumbs {
    text-align: center;

    .btn {

      margin-bottom: 3px;
    }

  }
}
