body.login {
  background: $left_bg;
}

#login_form {
  position: absolute;
  top: 40%;
  left: 0;
  margin-top: -50px;
  width: 100%;

  #login_box {
    margin: 0 auto;
    min-width: 240px;
    width: 25%;

    img {
      float: right;
    }
  }
}