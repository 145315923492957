$nucolor: #F15E22;

@import 'fonts';
@import 'bootstrap';
@import 'bootstrap_tweaks';

/* shared 3rd party css-------------------------------------------- */

@import 'nested_sortable';
@import 'awesome-bootstrap-checkbox';
@import 'color_picker';
@import 'bootstrap-editable';
@import 'select2/core';

/* nuCMS core ----------------------------------------------------- */

@import 'common';
@import 'admin_layout';

@import 'login';
@import 'images';
@import 'pages';
@import 'layouts';
@import 'rwd_related';
@import 'tinymce_override';

/* nuCMS components ------------------------------------------------ */

@import 'dashboard';
@import 'menu';
@import 'news';
@import 'galleries';
@import 'advanced_slider';
@import 'storage';
@import 'blog';
@import 'newsletter';