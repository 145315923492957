#comments_tree {
  .author {
    margin: 3px 0 0 5px;
    float: left;
  }
  .actions {
    float: right;
    margin-bottom: 1px;
    //margin-top: 1px;
    form {
      float: right;
      margin-left: 3px;
    }
  }
  textarea {
    resize: vertical;
  }
}