.advanced_slider_element_box {
  margin-top: 20px;

  .inner {
    padding: 0 10px;
  }

  table {
    margin-top: 1em;
    border: 1px $panel-default-border solid !important;
    th {
      background-color: $table-bg-accent;
    }
  }

  .image_actions {
    padding: 0 10px;
  }
}

.image_box .image {
  .thumb img {
    border: 1px $panel-default-border solid;
    max-height: 100px;
    margin-right: 10px;
    float: left;
  }

  .fileupload-progress {
    display: none;

    span {
      float: left;
    }

    .progress {
      margin-left: 10px;
      width: 400px;
      float: left;
    }

    .progress-extended {
      margin-left: 10px;
      float: left;
    }
  }

  .add_files {
    position: relative;
    height: 34px;
    width: 120px;
    float: left;
    margin-right: 3px;

    input {
      position: absolute;
      top: 0;
      opacity: 0;
      width: 120px;
      height: 34px;
    }

    button {
      text-align: center;
      position: absolute;
      width: 120px;
      top: 0;
    }

    &:hover {
      cursor: pointer;
      button {
        background-color: darken($btn-warning-bg, 10%);
        border-color: darken($btn-warning-border, 12%);
      }
    }
  }
}

.text_box {
  label {
    text-align: right;
    padding-top: 5px;
  }
  margin: 5px 0;
  overflow: hidden;
}

#slider_inner_wrapper {
  max-width:100%;
  overflow: auto;
  outline: 1px #ddd solid;
  background: #ddd;
  background-position: top center;
}

#slider_inner {
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  outline: 1px #f00 dashed;

  //background: rgba(255,255,255,0.3);
}

#slider_inner .progress_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(180, 180, 180, 0.5) url("../img/overlay.gif") 50% 50% no-repeat;
  display: none;
}

#source_model {
  display: none;
}

.slider_element {
  width: 100px;
  height: 100px;
  position: absolute !important;
  box-sizing: border-box;
  outline: 1px #ff2222 dashed;
  //background: rgba(210, 210, 210, 0.5);
  text-align: left;
  font-size: 1em;
}


// dopracowac

.slider_element {

  .actions {
    position: absolute;
    width: 120px;
    bottom: -25px;
    opacity: 0.1;
    z-index: 9999;
    .btn {

      span {
        margin-right: 0;
      }
    }
  }


  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &:hover {
    .actions {
      opacity: 1;
    }
  }
}


#element_edit_modal .modal-dialog {
  width: 1000px;

  hr {
    margin: 2px 0 5px 0;
  }

}

//.slider_element span {
//  font-size: 2em;
//  font-weight: bold;
//  line-height: 2em;
//  text-shadow: -2px 0 1px #fff, 2px 0 1px #fff, 0 2px 1px #fff, 0 -2px 1px #fff;
//}

#add_source {
  position: relative;
  height: 30px;
  width: 200px;
  margin: 10px 0;
  float: left;
}

#add_source input {
  top: 0;
  position: absolute;
  opacity: 0;
  width: 200px;
  height: 30px;
}

#add_source button {
  top: 0;
  text-align: center;
  position: absolute;
  width: 200px;
}

#add_source:hover {
  cursor: pointer;
}

#add_source:hover button {
  background-color: #286090;
  border-color: #204d74;
}

.show-preview {
  margin: 10px 3px;
}

//#images_section .image {
//  img {
//    max-width: 200px;
//    max-height: 100px;
//    float: left;
//  }
//  .options {
//    margin-left: 20px;
//    float: left;
//    position: relative;
//    height: 100%;
//  }
//}

#preview_modal .modal-dialog img {
  width: 100%;
  border: 1px #ddd solid;
}

/*# sourceMappingURL=multiimage.css.map */
