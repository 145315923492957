@keyframes tr_highlight {
  0% {
    background: #ffe3a3
  }
  80% {
    background: #ffe3a3
  }
  100% {
    background: none;
  }
}

html {
  background-color: #fff;
  height: 100%;
  width: 100%;
}

body {
  font-family: 'signika', helvetica, arial, sans-serif;
  height: 100%;
  width: 100%;
  min-width: 1000px;
}

a {
  text-decoration: none;
}

em {
  font-style: italic;
}

small {
  font-size: 8pt;
}

.hidden, .superadmin {
  display: none;
}

.superadmin-text {
  color: $state-danger-text;
  font-weight:bold;
}

.disabled {
  //color: $gray;
  a {
    color: $gray;
    cursor: inherit;
  }

  a:hover {
    text-decoration: none;
    color: $gray;
  }
}

.no_thumb {
  width: 48px;
  height: 48px;
  border: 1px darken($gray-lighter, 20%) solid;
  background: $gray-lighter;
  text-align: center;
  line-height: 48px;
}

.mini_thumb {
  max-height: 25px;
}

.panel-thumb {
  float: left;
  margin-right: $padding-base-horizontal;
  margin-bottom: $padding-base-vertical;
  width: 222px;
  display: block;
  background-color: $panel-footer-bg;
  .panel-body {
    height: 220px;
    background-color: $panel-bg;
  }
  .image_container {
    width: 200px;
    margin: 0 auto;

    img {
      max-width: 200px;
      max-height: 200px;
      margin: 0 auto;
      display: block;
    }
  }
  &.empty {
    img.loader {
      margin-top: 80px;
    }
  }

  .panel-footer {
    text-align: center;
  }
}

table .item.draft td {
  background-color: $alert-warning-bg;
}

.table-thumbs td img {
  max-height: 50px;
}

.table-thumbs-mini td img {
  max-height: 25px;
}

.table-valign-middle th, .table-valign-middle td {
  vertical-align: middle !important;
}

.message {
  // reserved class
  margin-bottom: 10px;
}

.label {
  font-family: Arial, Helvetica, sans-serif;
}


.subtable {
  width: 100%;
  td {
    //padding: 2px 5px;
  }

}


.info_icon {
  color: inherit;
  cursor: pointer;
  //display: block;
  //float: left;
  //margin: 4px 0 0 5px;
}


.change-order {
  cursor: ns-resize;
}

.change-order span {
  opacity: 0.1;
}

.table-hover tr:hover .change-order span {
  opacity: 1;
}

:target td {
  background: #fff2af !important;
}

tr.highlight {
  td {
    animation: tr_highlight 2s;
  }
}

.new_item {
  display: none;
}


.add_files {
  position: relative;
  height: 34px;
  width: 120px;
  float: left;
  margin-right: 3px;

  &.attachment {
    width: 150px;
  }

  input {
    position: absolute;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 34px;
  }

  button {
    text-align: center;
    position: absolute;
    width: 100%;
    top: 0;
  }

  &:hover {
    cursor: pointer;
    button {
      background-color: darken($btn-primary-bg, 10%);
      border-color: darken($btn-primary-border, 12%);
    }
  }
}

.width100 {
  width: 100% !important;
}
