#storage_section {
  .add_files {
    cursor: pointer;
    height: 26px;
    input {
      height: 26px;
    }
    &:hover {
      button {
        background-color: darken($btn-warning-bg, 10%);
        border-color: darken($btn-warning-border, 12%);
      }
    }
    &.float_none {
      float: none;
    }
  }

}