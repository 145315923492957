ol.nested_sortable {
  margin: 0;
  padding: 0;
  list-style-type: none;

  ol {
    list-style-type: none;
    padding: 0;
    margin: 0 0 0 25px;
  }

  li {
    overflow: hidden;
    margin: 3px 0 0 0;
    padding: 0;

    &.current > div {
      background: darken($gray-lighter, 15%);
    }

    .handle {
      cursor: move;
    }

    > div  {
      border-radius: 3px;
      margin: 0;
      padding: 2px 3px;
      border: 1px solid darken($gray-lighter, 15%);
      background: $gray-lighter;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      background: #f6f6f6;
    }
  }
}

.placeholder {
  outline: 1px dashed #4183C4;
}

.mjs-nestedSortable-error {
  background: #fbe3e4;
  border-color: transparent;
}



//li.mjs-nestedSortable-collapsed.mjs-nestedSortable-hovering div {
//  border-color: #999;
//  background: #fafafa;
//}
//
//.disclose {
//  cursor: pointer;
//  width: 10px;
//  display: none;
//}
//
//.nested_sortable li.mjs-nestedSortable-collapsed > ol {
//  display: none;
//}
//
//.nested_sortable li.mjs-nestedSortable-branch > div > .disclose {
//  display: inline-block;
//}
//
//.nested_sortable li.mjs-nestedSortable-collapsed > div > .disclose > span:before {
//  content: '+ ';
//}
//
//.nested_sortable li.mjs-nestedSortable-expanded > div > .disclose > span:before {
//  content: '- ';
//}
