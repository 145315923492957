#menu_tree {
  .title {
    margin-left: 5px;
    line-height: 26px;
  }
}

#menu_item_edit_modal {
  .modal-dialog {
    width: 700px;
  }
}
