@font-face {
  font-family: 'signika';
  src: url('../fonts/signika-light-webfont.eot');
  src: url('../fonts/signika-light-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/signika-light-webfont.woff') format('woff'),
  url('../fonts/signika-light-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'signika';
  src: url('../fonts/signika-semibold-webfont.eot');
  src: url('../fonts/signika-semibold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/signika-semibold-webfont.woff') format('woff'),
  url('../fonts/signika-semibold-webfont.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'signikaregular';
  src: url('../fonts/signika-regular-webfont.eot');
  src: url('../fonts/signika-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/signika-regular-webfont.woff') format('woff'),
  url('../fonts/signika-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}